.wrapper {
    width: 500px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 14px;
    justify-content: center;
}

.item {
    margin: 0 14px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    background: rgba(0,0,0,.4);
    color: #fff;
    transition: ease-in .05s;
    border: none;
}

.item:active{
    background-color: white;
    color: #000;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons div {
    color: #fff;
}

.buttonOk {
    border: none;
    margin-bottom: 20px;
    padding: 20px 40px;
    background: #4BB17B;
    color: #fff;
    font-size: 16px;
}

.buttonKo {
    color: #fff;
    border: none;
    background-color: rgba(0,0,0,.3);
    padding: 6px 20px;
    margin-top: 20px;
}

.welcomeUser {
    font-size: 32px;
    text-align: center;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0,0,0,.4);
}


.mainContent {

}

.footer {
    background-color: rgba(0,0,0,.1);
    margin-top: auto;
    text-align: center;
    color: #fff;
    height: 45px;

}

.footerRow {
    display: flex;
    align-items: center;
    height: 100%;
}

.footerRow > * {
    margin-right: 10px;
}
.footerRow > *:last-child {
    margin-right: 0;
}

.buttonPassword {
    border: none;
    background: #208bec;
    color: #fff;
    padding: 4px 8px;
}

.buttonPassword:disabled {
    opacity: .5;
}

@media(max-width: 767px) {
    .item {
        margin: 0 7px;
        width: 80px;
        height: 80px;
        font-size: 40px;
    }

    .wrapper {
        width: auto;
    }
}