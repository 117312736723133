//TODO implementar estilos
.g-modal {
    background: rgba(255,255,255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:hover {
        cursor: pointer;
    }
}

.g-modal-content {
    max-width: 1200px;
    padding: 40px;
    background: #fff;
    box-shadow: 0 0 80px rgba(0,0,0,.2);
    position: relative;

    &:hover {
        cursor: auto;
    }
}

.g-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: .3;
    transition: opacity .2s ease-in;
    outline: none;

    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}
