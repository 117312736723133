.wrapper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.topvideo {
    width: 100%;
    height: 100%;
    background: url("/wallpaper-pin.jpg") center center no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: 0;
    position: absolute;
}

.video {
    width: 100%;
    height: 100%;
    background: #000;

    video {
        width: 100%;
        height: 100%;
        //filter: blur(40px);
        object-fit: cover;
    }
}

.main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mainContent {
    margin: 0 auto;
    width: 700px;
    height: 100%;
    //background-color: #fff;
}

@media(max-width: 767px) {
    .mainContent {
        width: auto;
        margin: none;
    }
}
