$lmw: 600px;
$lw: 90%;


.login-wrapper {
    max-width: $lmw;
    width: $lw;
    background: #fff;
    padding: 20px;
    border: 1px solid $black;
    border-radius: 44px;


    .logo {
        width: 150px;
        display: block;
        margin: 25px auto;
    }

    .form-control {
        display: block;
        width: 100%;
        box-sizing: border-box;
        height: 40px;
        background-color: #fafafa;
        margin-bottom: 5px;
        padding: 5px;
        border: 1px solid #cdcdcd;
        font-size: 16px;
        outline: none;
    }

    .form-error {
        color: #a94442;
        font-size: 14px;

        a {
            color: #a94442;
        }
    }
}

.forgot-password {
    width: $lw;
    max-width: $lmw;
    text-align: right;
    margin-top: 10px;

    a {
        color: rgba(0, 0, 0, .7);
        text-decoration: none;
        font-size: 14px;

        &:hover {
            color: rgba(0, 0, 0, .9);
        }
    }
}


.login-banner {
    background: #454444;
    flex-basis: 0;
    //animation: colorchange 5s;
    //-webkit-animation: colorchange 30s infinite;
    order: 1;
    @media(min-width: 762px) {
        order: 0;
    }

    h1, h2 {
        color: rgba(255, 255, 255, .9);
        text-align: center;
    }
}


.login-form{
    background-color: $fondo;
    flex-basis: 0;
}

//@keyframes colorchange
//{
//    0%   {background: #00A14B;}
//    50%  {background: #1C75BC;}
//    100%  {background: #00A14B;}
//}
//
//@-webkit-keyframes colorchange
//{
//    0%   {background: #00A14B;}
//    50%  {background: #1C75BC;}
//    100%  {background: #00A14B;}
//}
