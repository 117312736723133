.sidebar-menu {
    width: 220px;
    background: #002851;
    transition: all ease-in .2s;
    display: flex;
    flex-direction: column;

    .logo{
        img{
            width: 150px;
            max-width: 90%;
            display: block;
            margin: 20px auto;
        }
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin-bottom: 50px;
    }

    a {
        transition: padding-left ease-in .2s;

        span {
            //opacity: 1;
            transition: opacity ease-in .1s;
        }

    }

    .toggleMenu {
        display: flex;
        margin-top: auto;
        margin-bottom: 10px;
        padding: 8px;
        opacity: .8;
        transition: opacity ease-in .2s;

        &:hover {
            opacity: .9;
        }

        div{
            transition: transform ease-in .1s;
        }
    }


    &.no-visible {
        width: 40px;

        a {
            padding-left: 10px;

            span {
                opacity: 0;
            }
        }

        .toggleMenu {
            div {
                transform: rotate(180deg);
            }
        }
    }
}

.navbar-nav {
    padding: 0;
    list-style: none;


    a {
        color: #fff;
        text-decoration: none;
        padding: 8px 0 8px 20px;
        border-bottom: 1px solid blue;
        opacity: .8;
        transition: all ease-in .3s;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        display: flex;
        align-items: center;

        span {
            margin-left: 4px;
        }

        &:hover, &.is-active {
            opacity: 1;
        }
    }
}

.toggleMenu {
    background: rgba(0, 0, 0, .2);
    padding: 4px;

    &:hover {
        cursor: pointer;
    }
}


