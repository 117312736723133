@import "variables";

/**
Fix para como pone los DIVS react native web. Aunque dicen que hay que poner flex 1....
https://github.com/necolas/react-native-web/issues/940
 */
#root {
    height: 100%;
    > div {
        height: 100%;
    }
}


body, html, .main-wrapper {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    background: #F2F4F5;
}

.main-wrapper {
    display: flex;
}

section.container {
    width: 1120px;
    margin: 0 auto;
    display: flex;
}

.row {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    @media(min-width: 763px) {
        flex-direction: row;
    }
    //margin: 0 -15px;
}

.col {
    padding: 0 15px;
    flex-grow: 1;
}

.wrapper-centered {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
}

.wrapper-centered-content {
    padding: 40px 80px;
    background: #fff;
    box-shadow: 0 0 120px rgba(0,0,0,.1);

    h1 {
        margin-top: 0;
        color: #666666;
        font-weight: normal;
    }
}

.d-flex {
    display: flex;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.jutify-content-center {
    justify-content: center;
}
