


//Para el detalle
.tiempoDetalle{
  p{
    margin-top: 0;
  }
  .title{
    margin-bottom: 0;
  }
  .desc{
    font-style: italic;
    text-indent: 10px;
  }
}

.tl-wrapper{
  margin-top: 20px;
  text-align: center;
}


