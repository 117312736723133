.wrapper {
    height: 60px;
    width: 200px;
    margin: 20px auto;
    border-bottom: 2px solid black;
    background: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
}

.content {
    display: flex;
    flex-grow: 1;

    justify-content: center;
}

.value {
    font-size: 40px;
    color: rgba(255, 255, 255, .8);
}

.delete {
    justify-self: flex-end;
    margin-right: 8px;
}
